import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchDairyManagedStore = defineStore('searchDairyManagedStore', {
  state: () => ({
    Dairy_id: null as number | null,
    useTerms: true,
    items: [{ key: 'Dairy_ident', operation: ':', itemType: 'string', isList: true }] as TermItemDefinition[]
  })
})
