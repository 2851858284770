<template>
  <div>
    <h2 v-html="$t('search_dairy_title')"></h2>
    <!-- eigene Verwertungsbetriebe -->
    <v-select
      id="search_dairy_ident"
      v-model="searchCardStore.Dairy_id"
      :label="$t('search_dairy_ident')"
      :items="dairies"
      item-title="ident"
      item-value="id"
      clearable
      @keyup.enter="search"
    />
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { apiURL } from '@/main'
import { showError } from '@/services/axios'
import { useLegalEntityStore } from '@/store/LegalEntityStore'
const legalEntityStore = useLegalEntityStore()
import { useSearchDairyManagedStore } from '@/store/SearchDairyManagedStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchMyDairy',
  extends: baseSearchVue,
  data() {
    return {
      dairies: []
    }
  },
  methods: {
    async getDairies() {
      let url = apiURL + '/legalEntities/' + legalEntityStore.getSelectedPersonId + '/dairies'
      try {
        const response = await this.axios.get(url)
        this.dairies = await response.data
      } catch (e) {
        showError(e)
      }
    }
  },
  beforeMount() {
    this.getDairies()
    this.setSearchCardStore(useSearchDairyManagedStore(), true)
  }
})
</script>
